<template>
  <div class="record_info">
    <div class="card_list">
      <div class="card_item">
        <div class="card_left">
          <div class="img_box">
            <img v-if="form.companyLogo" :src="form.companyLogo" alt="" />
            <img v-else src="../../../../assets/image/enterprise-default-logo.png" alt="" />
          </div>
          <div class="info_box">
            <div class="row">
              <div class="enterprise_name">
                {{ form.companyFullName }}
              </div>
            </div>
            <div class="row">
              <div
                class="type_tag"
                v-for="(item, index) in newIndustryLabel(form.industryLabel).slice(0, 2)"
                :key="index"
              >
                {{ item }}
              </div>
              <el-popover placement="right" width="400" trigger="hover">
                <div class="row_tag">
                  <div
                    class="type_tag1"
                    v-for="(lo, lh) in newIndustryLabel(form.industryLabel).slice(2)"
                    :key="lh"
                  >
                    {{ lo }}
                  </div>
                </div>
                <div
                  class="type_tag"
                  slot="reference"
                  v-show="newIndustryLabel(form.industryLabel).length > 2"
                >
                  {{ newIndustryLabel(form.industryLabel).length - 2 }}+
                </div>
              </el-popover>
            </div>
            <div class="row">
              <div>
                <span>{{ form.district || '-' }}</span>
              </div>
              <span class="bottom_time">走访时间：{{ form.visitTime || '-' }}</span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="btn_box">
            <div class="set_manager">
              <span class="manager_name"> 客户经理： </span>
              <div class="middle_icon_box">
                <img v-if="form.customerHead" :src="form.customerHead" alt="" />
                <img v-else src="@/assets/image/wait-set-manager.png" alt="" />
              </div>
              <span class="manager_name">
                {{ form.customerName }}
              </span>
            </div>
            <div class="btn_list">
              <el-button type="primary" @click="sumbitClick">返回一上级</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card_content">
      <div class="case_data">
        <div class="case_data_top">
          <span>需求信息</span>
        </div>
        <div class="case_data_over">
          <div class="case_data_over_height">
            <div class="case_data_over_top">
              <!-- <span class="left_txt">
                <img src="../../../../assets/image/icon_zf.png" alt="" />
                第一次走访记录</span
              > -->
              <span class="demand_time">走访时间：{{ detailInfo.visitTime }}</span>
            </div>
            <div class="case_data_dialog" ref="pdfContent">
              <div class="case_tabel">
                <div class="case_table_li">
                  <div class="left" style="width: 100%">
                    <div class="case_table_li_left">需求描述</div>
                    <div class="case_table_li_right">
                      {{ detailInfo.requirementDescribe }}
                    </div>
                  </div>
                </div>
                <div class="case_table_li">
                  <div class="left">
                    <div class="case_table_li_left">预算费用</div>
                    <div class="case_table_li_right">
                      {{ detailInfo.budget }}
                      <span>{{ detailInfo.budget ? '万元' : '-' }}</span>
                    </div>
                  </div>

                  <div class="right">
                    <div class="case_table_li_left">需求上传</div>

                    <div class="case_table_li_right">
                      <span class="file-name">{{
                        getFileName(detailInfo.requirementFile) || '-'
                      }}</span>

                      <a
                        v-if="detailInfo.requirementFile"
                        class="download-link"
                        :href="detailInfo.requirementFile"
                        target="_blank"
                      >
                        <i class="el-icon-download"></i>

                        <span class="file-name">点击下载</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="new_page">
            <el-pagination
              :current-page="queryInfo.pageNum"
              :page-sizes="[10, 30, 50]"
              :page-size="queryInfo.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// api
import { getSelectById, getDiagnosticLogoutApi, getDiagnostic } from '@/api/visiting'
import { getRequirementDetail } from '@/api/demand.js'

// utils
import { accAdd, base64ToFile } from '@/utils/function.js'
import { apiUpload, apiDownloadFile } from '@/api/common.js'

const defaultQueryInfo = Object.freeze({
  city: '贵阳市', // 省
  companyFullName: '', // 企业全称
  companyId: 0, // 企业主键
  customerId: 0, // 客户经理编号
  district: '', // 市
  province: '贵州省',
  industryCategoryList: [], // 国名经济行业标签
  labels: '', //
  orders: 4, // 排序
  pageNum: 1, // 页数
  pageSize: 10, // 每页数据条数
  queryConditions: '', // 企业名称或客户经理名称
  time: '', // 日期
  unitIds: [],
  questionnaireId: '',
  visitWay: '',
  industry: '',
  segmentedIndustry: '',
  optionIdList: []
})
export default {
  name: 'Diagnosisrecord',

  data() {
    return {
      activeName: 'first',
      form: {},
      total: 0, //
      queryInfo: { ...defaultQueryInfo }, //
      caseInfo: {},
      detailInfo: {}
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  mounted() {},
  created() {
    this.searchHeader()

    this.getRequirementDetailAPI()
  },
  methods: {
    getRequirementDetailAPI() {
      getRequirementDetail({ id: this.$route.query.id }).then((res) => {
        this.detailInfo = res.data
        console.log(this.detailInfo)
      })
    },
    // 文件名
    getFileName(url) {
      if (typeof url === 'string') {
        return url.substring(url.lastIndexOf('/') + 1)
      }
    },

    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
    },
    //监听页码变化
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
    },
    // 头部数据
    async searchHeader() {
      const res = await getSelectById({ id: this.id })
      if (res.resultCode === 200) {
        this.form = res.data
      } else {
        this.$message.warning('获取企业信息失败!')
      }
    },
    // 数据处理
    newIndustryLabel(val) {
      if (!val) {
        return []
      } else {
        return val.split(',')
      }
    },

    // 返回上级
    sumbitClick() {
      this.$router.go(-1)
    },

    // 折叠面板修改
    collapseChanged(val) {
      // console.log(val)
    },

    //查询建议部分 右侧数据
    async getDiagnosticTools() {
      let obj = {
        reportScore: this.reportScore, // 总得分
        id: this.$route.query.diagnoseId
      }
      const res = await getDiagnostic(obj)
      if (res.resultCode == 200) {
        this.loading = false
        this.simpleImageData = res.data
      }
    },

    //返回上一级
    back() {
      this.$router.go(-1)
    },
    drawLine() {
      this.btns.downloadPdfBtn = true
      let specificEchart = document.getElementsByClassName('specificechart')
      this.myChartList.forEach((item, index) => {
        let myChart = this.$echarts.init(specificEchart[index])
        let option = {
          grid: {
            // 设置图表距离顶部,左侧，右侧和底部的高度
            top: '0px',
            left: '0px',
            right: '0px',
            bottom: '0px'
          },
          color: ['#68A2FF', '#FF9C00', '#0062FF'],
          //配置维度的最大值
          radar: {
            // name: {
            //     formatter: function (value, indicator) {
            //     // 使用回调函数来格式化指示器名称
            //     return [`{div1|${value}}`,`{div2|20分}`].join('\n'); // 显示名称和最大值
            // },

            //   rich: {
            //     div1: {

            //       borderRadius: 5,
            //       padding: [5, 10],
            //       style: {
            //         fontSize: 16
            //         // 其他你想要的样式
            //       }
            //     },
            //     div2: {
            //       color: '#ff7a00',

            //       style: {
            //         fontSize: 18,
            //         padding: [5, 30],
            //         // 其他你想要的样式
            //       }
            //     }
            //   }
            // },

            axisName: {
              // color: '#0062FF',
              //backgroundColor: '#999',
              //borderRadius: 3,
              //padding: [3, 5]
              //指示器文字换行 start
              formatter: function (text) {
                var strlength = text.length
                if (strlength % 15 != 0) {
                  text = text.replace(/\S{15}/g, function (match) {
                    //console.log(match)
                    return match + '\n'
                  })
                } else {
                  text = text.replace(/\S{15}/g, function (match) {
                    //console.log(match)
                    return match + '\n'
                  })
                  strlength = text.length
                  text = text.substring(0, strlength - 1)
                }
                return text
              }
            },
            center: ['50%', '50%'], //调位置  第一个左右   第二个上下
            radius: 100, //调大小
            // 雷达图的指示器，用来指定雷达图中的多个变量（维度）
            indicator: item.dataMax,
            //对雷达图形设置成一个圆形,可选 circle:圆形,polygon:多角形(默认)
            shape: 'circle',
            splitArea: {
              itemStyle: {
                //color: '#FF7A00',
                //borderColor: '#FF7A00'
              },
              areaStyle: {
                // 图表背景网格区域的颜色
                //color: 'rgba(71,237,252,.3)'
                //color: '#FF7A00', //分割区域的背景色
              }
            },
            axisLine: {
              show: true, //雷达图中心发出的射线：坐标轴轴线相关设置
              lineStyle: {
                type: 'dashed',
                color: 'rgba(104,162,255,0.9)'
              }
            },
            splitLine: {
              //圆圈线条样式
              lineStyle: {
                color: 'rgba(104,162,255,.9)'
              }
            }
          },
          series: [
            {
              type: 'radar',
              label: {
                show: false, //控制是否显示数值
                fontSize: 12,
                color: '#FF7A00',
                margin: 20
              },
              // areaStyle: {}, //每个雷达图形成一个阴影的面积
              itemStyle: {
                //折线拐点标志的样式
                color: '#FF7A00',
                borderColor: '#FF7A00'
              },
              areaStyle: {
                // 折线围成区域的颜色
                color: '#FF7A00' //分割区域的背景色
              },
              lineStyle: {
                //线条样式
                color: '#FF7A00'
              },
              symbolSize: 4, //圆点大小
              symbol: 'circle', //圆点样式
              data: [item.data]
            }
          ]
        }
        myChart.setOption(option) // 绘制图表
        setTimeout(() => {
          this.loading = false
          var opts = {
            type: 'png', // 导出的格式，可选 png, jpeg
            pixelRatio: 1, // 导出的图片分辨率比例，默认为 1。
            backgroundColor: '#fff' // 导出的图片背景色，默认使用 option 里的 backgroundColor
          }
          //拿到base64 地址 传入files数组
          this.files.push(base64ToFile(myChart.getDataURL(opts), index))
          this.downloadParam.urls.push('')
          this.uploadFile(this.files[index], index)
        }, 1000)
        setTimeout(() => {
          this.btns.downloadPdfBtn = false
        }, 3000)
      })
    },
    // 上传图片
    async uploadFile(item, index) {
      const form = new FormData()
      form.append('file', item)
      const res = await apiUpload(form)
      if (res.resultCode === 200) {
        this.downloadParam.urls[index] = res.data
        this.simpleImageData.imgList[index].img = res.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
.case_data_dialog {
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0px;

  .download {
    display: flex;
    margin-left: 10px;
    align-items: center;
    box-sizing: border-box;
    padding: 6px 10px;
    border-radius: 4px;
    border: 1px solid #4e93fb;
    color: #4e93fb;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;

    img {
      width: 20px !important;
      height: 20px !important;
    }
  }

  .compny_flex {
    padding: 12px 0px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e7e7e7;

    img {
      width: 30px;
      height: 30px;
    }
    .right_border {
      display: inline-block;
      width: 2px;
      height: 20px;
      background: #4e93fb;
    }
    .right {
      margin-left: 14px;
      color: #4e93fb;
      font-size: 16px;
    }
    .color_add {
      color: #448aff;
      margin-left: 10px;
    }
  }

  .case_tabel .case_table_li:nth-last-child(1) {
    border-bottom: 1px solid #dcdee0;
  }

  .case_table_li {
    .file-name {
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: #4e93fb;
      text-decoration: underline;
    }

    .download-link {
      display: flex;
      align-items: center;
      margin-left: 34px;
      color: #4e93fb;

      i {
        font-size: 18px;
      }
    }
  }

  .case_tabel {
    width: 100%;
    overflow: hidden;
    font-size: 16px;
    margin-top: 10px;
    font-weight: 400;
    .img_radio {
      width: 20px;
      height: 20px;
    }
    .case_table_li {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      border-right: 1px solid #dcdee0;
      border-top: 1px solid #dcdee0;

      .left {
        width: 50%;
        display: flex;
        align-items: center;
        line-height: 80px;
        box-sizing: border-box;
      }

      .right {
        width: 50%;
        display: flex;
        align-items: center;
        line-height: 80px;
        box-sizing: border-box;
      }

      .case_table_li_left {
        width: 120px;
        background: #fafafa;
        display: flex;
        font-weight: 550;
        align-items: center;
        border-right: 1px solid #dcdee0;
        justify-content: center;
        border-left: 1px solid #dcdee0;
      }
      .case_table_li_left_two {
        width: 120px;
        background: #fafafa;
        font-weight: 550;
        line-height: 40px;
        border-right: 1px solid #dcdee0;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-left: 1px solid #dcdee0;
        .po_bottom {
          position: relative;
          top: -8px;
        }
        .po_top {
          position: relative;
          top: 8px;
        }
      }
      .case_table_li_right {
        flex: 1;
        display: flex;
        box-sizing: border-box;
        padding: 0 12px 0px 30px;
        align-items: center;
        line-height: 2em;
        min-height: 80px;
        flex-shrink: 1;
        .customerHead {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          border-radius: 50%;
        }
        .img {
          display: flex;
          align-items: center;
          img {
            width: 100px;
            height: 64px;
            border-radius: 8px;
            margin-right: 16px;
          }
        }
      }
    }
  }
}
.case_data {
  display: flex;
  flex-flow: column;
  width: 100%;
  padding: 0px 0px;
  box-sizing: border-box;
  .case_data_top {
    width: 120px;
    padding: 10px 10px;
    font-size: 18px;
    color: #448aff;
    background: #fff;
    border-radius: 2px;
  }
  .case_data_over {
    padding: 20px 20px;
    background-color: #fff;
    .case_data_over_height {
      min-height: calc(100vh - 350px);
    }
    .new_page {
      width: 100%;
      margin: 0 auto;
      padding: 10px;
      box-sizing: border-box;
      background-color: #fff;
      border-top: #fafafa 1px solid;
      text-align: center;
    }
    .case_data_over_top {
      font-size: 16px;
      .left_txt {
        color: #ffa858;
        margin-right: 20px;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .demand_time {
        color: #7c7f8e;
      }
    }
    /* 滚动条整体部分 */
    &::-webkit-scrollbar {
      width: 3px;
      height: 9px;
    }
    /* 滚动条里面的滑块 */
    &::-webkit-scrollbar-thumb {
      background-color: rgba(144, 147, 153, 0.3);
      border-radius: 20px;
      transition: background-color 0.3s;
    }
  }
  .new_page {
    margin: auto 0;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    text-align: center;
  }

  .row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;

    .span {
      color: #393939;
      font-size: 16px;
      font-weight: 400;
    }

    ::v-deep .el-form--inline .el-form-item {
      margin-bottom: 0 !important;
    }

    .search_form {
      display: flex;
      align-items: center;
    }

    .search_right {
      margin-right: 20px;
      ::v-deep .el-button {
        border: 1px solid #448aff;
        color: #fff;
        border-radius: 0;
        background-color: #448aff;
      }
    }
  }
}
.record_info {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
  .card_content {
    margin-top: 16px;
  }
  .card_list {
    display: flex;
    flex-flow: column;
    width: 100%;
    .po_reportingStatus {
      position: absolute;
      color: #f53939;
      top: 50%;
      transform: translateY(-50%);
      left: 28%;
      z-index: 999 !important;
      img {
        width: 76px;
        height: 60px;
      }
    }
    .card_item {
      display: flex;
      align-items: center;
      width: 100%;
      background-color: #fff;
      padding: 10px 20px;
      box-sizing: border-box;
      position: relative;
      .card_top {
        position: absolute;
        top: 10px;
        left: 6px;
        height: 30px;
        min-width: 84px;
        color: #ffffff;
        z-index: 99999;
        text-align: center;
        line-height: 30px;
        border-radius: 15px 20px 20px 0;
      }
      .card_left {
        display: flex;
        align-items: center;
        padding-top: 10px;
        box-sizing: border-box;
        width: 40%;
        margin-right: 20px;
        .item_checkbox {
          line-height: 36px;
          margin: 0px 10px;
        }
        .img_box {
          width: 88px;
          height: 88px;
          margin-right: 10px;
          border-radius: 8px;
          overflow: hidden;
          position: relative;
          img {
            width: 100px;
            height: 100px;
          }
          .tag_box {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 60px;
            height: 20px;
            color: #fff;
            font-size: 12px;
            line-height: 20px;
            text-align: center;
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            position: absolute;
            top: 10px;
            left: 0;
            z-index: 999;
          }
        }
        .info_box {
          display: flex;
          justify-content: center;
          flex-flow: column;
          .bottom_time {
            margin-left: 20px;
          }
          .enterprise_name {
            cursor: pointer;
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
            color: #000;
            max-width: 340px; /* 固定宽度超出隐藏 */
            overflow: hidden; /*  超出隐藏 */
            text-overflow: ellipsis; /*  超出以 ...显示 */
            display: -webkit-box;
            -webkit-line-clamp: 1; /* // 显示1行 */
            -webkit-box-orient: vertical;
          }

          .tag_list {
            display: flex;
            .tag_item {
              font-size: 10px;
              color: #4e93fb;
              border: #559eff 1px solid;
              border-radius: 4px;
              padding: 5px;
              margin-right: 6px;
            }

            .show_more {
              position: relative;
              font-size: 14px;
              line-height: 26px;
              text-align: center;
              cursor: pointer;
              .show_tag_box {
                position: absolute;
                top: 100%;
                left: 100%;
                z-index: 99;
                width: 240px;
                padding: 6px;
                box-sizing: border-box;
                border-radius: 4px;
                background-color: #fff;
                border: #f8f8f8 1px solid;
                display: flex;
                flex-flow: row wrap;
                li {
                  padding: 3px 5px;
                  .tag {
                    width: fit-content;
                    font-size: 10px;
                    line-height: 20px;
                    color: #4e93fb;
                    border: #559eff 1px solid;
                    border-radius: 4px;
                    padding: 0 6px;
                    box-sizing: border-box;
                  }
                }
              }
            }
          }
        }
      }
      .card_right {
        flex: 1;
        display: flex;
        .box_list {
          display: flex;
          align-items: center;
          .item {
            display: flex;
            justify-content: center;
            text-align: center;
            margin-right: 30px;
            .num {
              cursor: pointer;
              font-weight: bold;
              font-size: 24px;
              color: #4e93fb;
              margin-bottom: 10px;
            }
            .num1 {
              font-weight: bold;
              font-size: 24px;
              color: #333333;
              margin-bottom: 10px;
            }
            .txt {
              font-size: 16px;
              margin-right: 15px;
              color: #151515;
            }
          }
          // .item:hover {
          //   .num {
          //     color: #448aff;
          //   }
          //   .icon_box {
          //     color: #448aff;
          //   }
          //   span {
          //     color: #448aff;
          //   }
          // }
        }
        .btn_box {
          display: flex;
          align-items: center;
          margin-left: auto;
          margin-right: 10px;
          .set_manager {
            display: flex;
            align-items: center;
            min-width: 120px;
            margin-right: 50px;
            .middle_icon_box {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: #f4f4f4;
              overflow: hidden;
              margin-right: 10px;
              img {
                width: 35px;
                height: 35px;
              }
              i {
                font-size: 16px;
                color: #c9c9c9;
              }
            }
            .manager_name,
            .wait_set {
              cursor: pointer;
              font-size: 16px;
            }
          }
          .divs {
            margin-right: 10px;
          }
          .set_manager {
            display: flex;
            align-items: center;
            min-width: 120px;
            margin-right: 50px;
            .middle_icon_box {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 35px;
              height: 35px;
              border-radius: 50%;
              background-color: #f4f4f4;
              overflow: hidden;
              margin-right: 10px;
              img {
                width: 35px;
                height: 35px;
              }
              i {
                font-size: 16px;
                color: #c9c9c9;
              }
            }
            .manager_name,
            .wait_set {
              cursor: pointer;
              font-size: 16px;
            }
          }
          .btn_list span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
